.desktop {
  align-items: center;
  background-color: #003cd4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  /* padding: 1rem; */
  position: relative;
  width: 100%;
}

.desktop .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  justify-content: center;
  position: relative;
  width: 100%;
}

.desktop .div-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.desktop h1 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Bebas Neue", Helvetica !important;
  font-size: 6rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.desktop .text-wrapper {
  color: #ffffff;
  font-family: "Bebas Neue", Helvetica !important;
  font-size: 4.5rem;
  font-weight: 700;
  letter-spacing: 0;
}

.desktop .span {
  font-size: 9rem;
}

@media only screen and (max-width: 600px){
  .desktop .text-wrapper {
    font-size: 3.5rem;

  }
  .desktop h1 {
    font-size: 5rem;
  }
}

.desktop .strategic-marketing {
  align-self: stretch;
  color: #ffffff;
  font-family: "Circular Std-Book", Helvetica;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  display: inline-block;

}

.desktop .div {
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.4rem;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1rem;
  justify-content: center;
  padding: 1rem 1.5rem 1rem 1.2rem;
  position: relative;
}

.desktop .calendar-lines {
  position: relative;
  width: 1.2rem;
}

.desktop .book-a-consult {
  color: #000000;
  font-family: "Circular Std-Bold", Helvetica;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.desktop .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  padding: 0rem 3rem 2rem;
 width: 100%;
}

.desktop .text-wrapper-2 {
  color: #ffffff;
  font-family: "Circular Std-Medium", Helvetica;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.5rem;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-3 {
  color: #ffffff;
  font-family: "Circular Std-Medium", Helvetica;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  width: fit-content;
}
